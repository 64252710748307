//@ts-nocheck
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Row, Col, Button, Space, Badge, Dropdown, Menu, Avatar, Tabs } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { NotificationIcon, DropdownIcon, BurgerIcon } from '../../../../components/icons/Icons';
import logo from '../../../../assets/svg/logo-text-row.svg';
import NotificationsView from '../../../notifications/NotificationsView';
import DynamicDialog from '../../../../components/DynamicDialog';
import socketIOClient from 'socket.io-client'
import APPConfig from '../../../../config';
import './HeaderView.less';
import ManageNotificationService from '../../../../services/api/ManageNotificationService';
import AuthService from '../../../../services/api/AuthService';
import UserService from '../../../../services/api/UsersServices';

const socket = socketIOClient(APPConfig.SOCKET_URL, { transports: ['websocket', 'polling', 'flashsocket'] })
const notificationService = new ManageNotificationService();
const userService = new UserService();
const authService = new AuthService();

const { TabPane } = Tabs
const HeaderView = (props: any) => {
    const history = useHistory();
    const [user, setUser]: any = useState(null);
    const [notifsDataCount, setNotifsDataCount]: any = useState([])
    const [currentPath, setCurrentPath]: any = useState("")
    const [modalClassname, setModalClassname]: any = useState()
    const [dialogContent, setDialogContent] = useState({})
    const [isVisible, setIsVisible] = useState(false)
    const [isReloading, setIsReloading] = useState(false)
    const [dropDownActive, setDropDownActive] = useState(false)

    const headerRef: any = useRef()
    const dropDownRef: any = createRef()



    useEffect(() => {
        const userData: any = authService.getUser()
        if (userData && userData?.isFullyRegistered && !user) {
            onLoadNotifications()
            socket.on('customer_notifications_' + user?.customer?._id, () => {
                onLoadNotifications()
            })

            return () => {
                socket.disconnect()
            }
        }

    }, [])

    useEffect(() => {
        if (props.closeDropdown) {
            setDropDownActive(false)
        }
    }, [props.closeDropdown])


    useEffect(() => {
        const userData: any = authService.getUser()
        if (!user && userData && userData?.isFullyRegistered) {
            setUser(userData?.customer);
        }

        if (userData && !userData?.isFullyRegistered && !history.location.pathname.includes("onboarding")) {
            localStorage.clear()
        }

        setCurrentPath(history.location.pathname)
    }, [history.location]);

    useEffect(() => {

        if (headerRef.current) {
            props.onLoadHeight(headerRef.current.offsetHeight)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerRef.current, dropDownRef.current])


    const onLoadNotifications = async () => {
        const result = await notificationService.getAllNotification()
        const notifsCount = result?.unread_count
        setNotifsDataCount(notifsCount)
    }

    const onLogout = async () => {
        const authService = new AuthService();
        const userCustomer = await authService.getUser();
        await authService.logout(userCustomer?._id)
        localStorage.clear();
        if (props.history.location.pathname === "/") {
            setIsReloading(true)
            return window.location.reload()
        }
        return props.history.push('/');

    }

    const toggleSidebar = () => {
        props.collapseToggle(!props.siderCollapsed)
    }

    const menu = (
        <Menu className="sd-dropdown">
            <Menu.Item key="1" onClick={() => history.push("/profile")} className="link-secondary">
                <span className="c-pointer fw-600">
                    <span>My Profile</span>
                </span>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => history.push("/settings")} className="link-secondary">
                <span className="c-pointer fw-600">
                    <span>Settings</span>
                </span>
            </Menu.Item>
            <Menu.Item key="2" className="link-secondary" onClick={() => onLogout()}>
                <span className="c-pointer fw-600">
                    <span>Log Out
                    </span>
                </span>
            </Menu.Item>
        </Menu>
    );
    const items: MenuProps['items'] = [
        {
            label: <a href="https://www.antgroup.com">1st menu item</a>,
            key: '0',
        },
        {
            label: <a href="https://www.aliyun.com">2nd menu item</a>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ];

    const renderBreadcrumb = () => {
        const { pathname, state } = history.location
        const pathNames = pathname.split("/").filter((path: string) => !!path)
        const items = (pathNames.length > 1 && !pathNames.includes("plan-summary")) ?

            pathNames.reduce((items: Array<any>, path: string, index: number, pathnames: Array<any>) => {

                // Will appear after every item except for the last one
                const indicator = pathnames[index + 1] ? <span className='breadcrumb-indicator'>{">"}</span> : null

                if (path === "plan-date") {
                    items = [...items, <Col onClick={() => history.replace("/plan-date")} className='breadcrumb-item c-pointer'>Plan Date</Col>, indicator]
                }


                if (path === "swiping-game" && pathnames[index + 1] !== "send-invite") {
                    //Change style if active
                    items = [...items, <Col className={`breadcrumb-item ${index === (pathnames.length - 1) ? "active" : ""} c-pointer`}>Add Experience</Col>, indicator]
                }

                if (path === "send-invite") {
                    items = [...items, <Col onClick={() => history.replace("/plan-date/send-invite", state)} className={`breadcrumb-item ${index === (pathnames.length - 1) ? "active" : ""} c-pointer`}>Who do you want to Invite?</Col>, indicator]
                }

                return items.filter((item: any) => item !== null)
            }, [])
            : null
        return items

    }

    const handleAllNotifications = () => {
        history.push('/notifications')
        setIsVisible(false)
    }

    const handleMarkAllAsRead = async () => {
        await notificationService.markAllAsRead()
        setIsVisible(false)
    }


    const handleShowNotifications = () => {
        setModalClassname('modal-notifications')
        setIsVisible(true)
        setDialogContent({
            rightOffset: dropDownRef?.current?.offsetWidth,
            footer: (
                <Row onClick={handleAllNotifications} justify='center' align='middle' style={{ padding: 10 }}>
                    <span style={{ cursor: 'pointer', font: 'normal normal 600 14px/18px Avenir', color: '#6930a3' }}>
                        See All Notifications
                    </span>
                </Row>
            ),
            title: (
                <>
                    <span>Notifications</span>
                    <a onClick={handleMarkAllAsRead}>Mark All As Read</a>
                </>
            ),
            width: 370,
            component: <NotificationsView />
        })
    }

    const pathNames = history.location.pathname.split("/").filter((path: string) => path)
    return (
        <>
            <DynamicDialog
                bodyStyle={{ overflowY: 'scroll', height: 500 }}
                visibility={isVisible}
                content={dialogContent}
                closable={false}
                className={modalClassname}
                onSetVisibility={(value: boolean) => {
                    setIsVisible(value)
                }}
            />
            <Row id='header' ref={headerRef} className={`sd-nav-bar bg-secondary${isVisible ? ' modal-present' : ''}`}>
                <Col span={24}>
                    <Row justify="space-between" align="middle" className="main-header" style={{
                        flexDirection: ((user && window.innerWidth <= 768 && history.location.pathname === "/settings") ||
                            (user && window.innerWidth <= 768 && history.location.pathname === "/profile") ||
                            (user && window.innerWidth <= 768 && history.location.pathname === "/notifications") ||
                            (!user && history.location.pathname === "/") ||
                            (user && history.location.pathname === "/" && window.innerWidth > 768) ||
                            (user && history.location.pathname.match(/date-nights.*details/g) && window.innerWidth <= 768) ||
                            (!user && history.location.pathname.includes("/get-inspired") && window.innerWidth <= 768) ||
                            (!user && history.location.pathname.includes("/date-invitation-request") && window.innerWidth <= 768) ||
                            (!user && history.location.pathname === "/privacy-policy") ||
                            (user && history.location.pathname === "/privacy-policy" && window.innerWidth > 768) ||
                            (!user && history.location.pathname === "/terms-and-conditions") ||
                            (user && history.location.pathname === "/terms-and-conditions" && window.innerWidth > 768) ||
                            (user && history.location.pathname === "/terms-and-conditions" && window.innerWidth <= 768) ||
                            (user && history.location.pathname === "/privacy-policy" && window.innerWidth <= 768)
                        ) ? 'row' : 'row-reverse'
                    }}>

                        {((user && window.innerWidth <= 768 && history.location.pathname === "/settings") || (user && window.innerWidth <= 768 && history.location.pathname === "/notifications") || (user && window.innerWidth <= 768 && history.location.pathname === "/profile") ||
                            (user && history.location.pathname.match(/date-nights.*details/g)
                                || (user && window.innerWidth <= 768 && history.location.pathname === "/terms-and-conditions")
                                || (user && window.innerWidth <= 768 && history.location.pathname === "/privacy-policy")
                                && window.innerWidth <= 768)) &&
                            <Col className='c-pointer' align='middle' onClick={() => history.goBack()}>
                                <Row align='middle'>
                                    <Col>
                                        <ArrowLeftOutlined className='text-primary' style={{ fontSize: 18 }} />
                                    </Col>
                                    <Col style={{ paddingTop: 5, paddingLeft: 5 }}>
                                        <span style={{ margin: 0 }} className='header-title text-white fw-600'>Back</span>
                                    </Col>
                                </Row>
                            </Col>}

                        {!user && history.location.pathname.includes("onboarding") ?
                            <Col align='middle'>
                                <Row align='middle'>
                                    <Col>
                                        <Row align='middle'>
                                            <Button type="primary" htmlType="button" className="skip-btn" onClick={props.onSkipOnboarding}>
                                                SKIP
                                            </Button >
                                        </Row>
                                    </Col>
                                </Row>
                            </Col> : null}
                        <Col flex={'auto'} style={{ textAlign: (user && window.innerWidth <= 768) ? 'center' : 'left' }}>

                            {

                                // pathNames[pathNames.length - 1] === "plan-summary" && window.innerWidth <= 768  ? 
                                //     <Space className='c-pointer' onClick={() => history.push("/plan-date/send-invite",history.location.state)} align='center'>
                                //         <Col>
                                //             <ArrowLeftOutlined className='text-primary' style={{ fontSize: 28 }}  />
                                //         </Col>
                                //         <Col>
                                //             <h2 style={{ margin: 0 }} className='header-title text-white'>Back</h2>
                                //         </Col>
                                //     </Space> : 
                                user ? <Space style={{ marginRight: (user && window.innerWidth <= 768) ? 35 : 0 }}>
                                    <Col onClick={() => history.push("/")} className="logo-container c-pointer">
                                        <Space direction="vertical" size={10}>
                                            <img src={logo} alt="Secret Dates" className="logo" />
                                        </Space>
                                    </Col>
                                </Space> : <Space >
                                    <Col onClick={() => {
                                        if (history.location.pathname.includes("onboarding")) {
                                            return history.goBack()
                                        }

                                        return history.push("/")
                                    }} className="logo-container c-pointer">
                                        <Space direction="vertical" size={10}>
                                            <img src={logo} alt="Secret Dates" className="logo" />
                                        </Space>
                                    </Col>
                                </Space>}

                        </Col>

                        {history.location.pathname.includes("onboarding") ? null : user === null ? <Col>
                            <Row align="middle" className='header-rightIcon'>
                                <Col>
                                    <Row align='middle' className='login-div'>
                                        <a className="logintxt font-25 fw-600 text-white" href="/login">
                                            LOGIN
                                        </a>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row align='middle'>
                                        <Button type="primary" htmlType="submit" className="join-now-btn" onClick={() => history.push("/register")}>
                                            JOIN NOW
                                        </Button >
                                    </Row>
                                </Col>
                            </Row>
                        </Col> : null}

                        <Col ref={dropDownRef} span={user ? 'auto' : 0}>
                            <Row align="middle" gutter={[12, 0]} className='header-rightIcon'>
                                <Col className='hidden-xs'>
                                    <Badge offset={[notifsDataCount.length > 2 ? 0 : -8, 8]} className='sd-badge' count={notifsDataCount}>
                                        <div className="icon-container c-pointer">
                                            <NotificationIcon onClick={handleShowNotifications} />
                                        </div>
                                    </Badge>
                                </Col>

                                <Col className='c-pointer' onClick={() => {
                                    if (window.innerWidth <= 768) {
                                        toggleSidebar()
                                    }
                                }}>
                                    {user?.customer_image ? <Avatar className='sd-user-avatar' src={user?.customer_image} /> : user?.customer ? <Avatar className='sd-user-avatar' src={user?.customer?.customer_image} /> : (!user ? <Avatar className='sd-user-avatar placeholder' icon={<UserOutlined />} /> : (user && (!user?.customer_image || !user?.customer?.customer_image)) ? <Avatar className='sd-user-avatar placeholder' icon={<UserOutlined />} /> : null)}
                                </Col>
                                <Col className='hidden-xs'>
                                    <Dropdown overlay={menu} visible={dropDownActive} onVisibleChange={() => setDropDownActive(!dropDownActive)} getPopupContainer={() => document.getElementById('header')} trigger={['click']} className="c-pointer">
                                        <Row align='middle'>
                                            <p id='sd-user-name' className='text-white'>
                                                {user?.name ? "Hi. " + user?.name : ""}
                                            </p>
                                        </Row>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default withRouter(HeaderView)