import APIService from "./APIService";

class UsersService extends APIService
{
    search = async (search:string = '', page:any = 1, limit:any = 10) => {
        const result = await this.get('users/search?generic=' + search + '&page=' + page + '&limit=' + limit);
        return result ? result.data : null;
    }

    getUser = async(id:string) => {
        const result = await this.get('users/' + id);
        return result ? result.data : null;
    }

    addUser = async(formData:any) => {
        const result = await this.post(formData, 'users/');
        return result ? result.data : null;
    }

    editUser = async(id:string, formData:any) => {
        const result = await this.put(formData, 'profile/' + id);
        return result ? result.data : null;
    }

    editSettings = async(id:string, formData:any) => {
        const result = await this.put(formData, 'profile/settings/' + id);
        return result ? result.data : null;
    }

    deleteUser = async(id:string) => {
        const result = await this.remove('profile/' + id);
        return result ? result.data : null;
    }

    getUserByPhone = async(phone_number:string) => {
        const result = await this.get('profile/' + phone_number);
        return result ? result.data : null;
    }

    activateUser = async(id:string, formData: any) => {
        const result = await this.patch(formData, 'users/' + id + '/activate');
        return result ? result.data : null;
    }

    deactivateUser = async(id:string, formData: any) => {
        const result = await this.patch(formData, 'users/' + id + '/deactivate');
        return result ? result.data : null;
    }

    editImages = async (id: string, { file }: any) => {
        const result = await this.put({ file }, 'users/' + id + "/image");
        return result ? result.data : null;
    }

    uploadImg = async(id:string, file:any) => {
        const result = await this.post(file, 's3/upload?tmp=' + id);
        return result ? result.data : null;
    }

}

export default UsersService