import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({auth, restricted = true, ...props}:any) => {
	const isLoggedIn = auth.isLoggedIn();

    // restricted = false meaning public route
    // restricted = true meaning restricted route
    return isLoggedIn &&  restricted
        ? (<Redirect to="/" />)
        : (<Route {...props} />)
}

export default PublicRoute;