import APIService from "./APIService";



class AuthService extends APIService
{

   login = async ({phone_number, password}:any, initial = false) => {
      const result = await this.post({phone_number, password}, `auth/login${initial ? '?initial=1': ''}`);
      
      return result ? result.data : null;
   }

   fbLogin = async ({email, userID}:any, initial = false) => {
      const result = await this.post({email, userID}, `auth/fb-login${initial ? '?initial=true': ''}`);
        
      return result ? result.data : null;
   }

   googleLogin = async ({email, userID}:any) => {
      const result = await this.post({email, userID}, `auth/google-login`);
        
      return result ? result.data : null;
   }

   googleProfile = async (access_token: string) => {
      const userInfo = await this.client.get(
         'https://www.googleapis.com/oauth2/v3/userinfo',
      { headers: { "Authorization": `Bearer ${access_token}` } }
      )

      return userInfo && userInfo.data
   }

   fbSignup = async ({email, userID, customer_image, name}:any) => {
      const result = await this.post({email, userID, customer_image, name}, `auth/fb-signup`);
        
      return result ? result.data : null;
   }

   googleSignup = async ({email, userID, customer_image, name}:any) => {
      const result = await this.post({email, userID, customer_image, name}, `auth/google-signup`);
        
      return result ? result.data : null;
   }

   forgotPassword = async ({phone_number}:any) => {
      const result = await this.post({phone_number}, 'auth/forgot-password');
        
      return result ? result.data : null;
   }

   resetPassword = async ({token, new_password, confirm_password}:any) => {
      const result = await this.post({token, new_password, confirm_password}, 'auth/reset-password');
        
      return result ? result.data : null;
   }

   isLoggedIn = () => {
      return localStorage.getItem('access_token') ? true : false;
   }

   getUser = () => {
      const user_result: any = localStorage.getItem('user')
      const user_data = JSON.parse(user_result) || {}
      let is_fully_registered = false
      const {customer, ...user} = user_data || {}
      if(customer?.name && (!user?.google_user_id || !user?.fb_user_id) && user?.is_active){
         is_fully_registered = true
      }
      if(customer?.name && user?.google_user_id && user?.is_active){
         is_fully_registered = true
      }

      if(customer?.name && user?.fb_user_id && user?.is_active){
         is_fully_registered = true
      }
      return user_data && Object.keys(user_data).length ? {...user_data, isFullyRegistered: is_fully_registered} : null
   }

   isFullyRegistered = () => {
      const user_local =  localStorage.getItem('user') || "{}"
      const {  customer, ...user } = JSON.parse(user_local)
      let is_fully_registered = false;
      if(customer?.name && (!user?.google_user_id || !user?.fb_user_id) && user?.is_active){
         is_fully_registered = true
      }
      if(customer?.name && user?.google_user_id && user?.is_active){
         is_fully_registered = true
      }

      if(customer?.name && user?.fb_user_id && user?.is_active){
         is_fully_registered = true
      }
      return is_fully_registered
   }

   logout = async (user_id: any) => {
      const result = await this.post({user_id}, 'auth/logout');
      return result ? result.data : null;
   }

   test = async () => {
      const result = await this.get('test-api-call');

      return result ? result.data : null;
   }

   verifyUser = async (id: any) => {
      const result = await this.get(`auth/verify-user/${id}`)
      return result ? result.data : null;
   }

   verifyMerchant = async (token: any) => {
      const result = await this.get(`auth/verify-merchant/${token}`)
      return result ? result.data : null;
   }

   verifyInvitation = async (token: any) => {
      const user = this.getUser();
      const id = user?.customer?._id || ""
      const result = await this.get(`auth/verify-invitation/${token}?authId=${id}`)
      return result ? result.data : null;
   }

   register = async ({phone_number, password, invitation_token = "", name = ""}:any) => {
      const result = await this.post({phone_number, password, invitation_token, name}, 'auth/register');
      return result ? result.data : null;
   }

   preRegister = async (formData: any) => {
      const result = await this.post(formData, 'auth/pre-register');
      return result ? result.data : null;
   }

   preRegisterPartner = async (formData: any) => {
      const result = await this.post(formData, 'auth/pre-register-partner');
      return result ? result.data : null;
   }

   onboardingGeneralInfo = async(id:string, formData:any) => {
      const result = await this.put(formData, 'auth/onboarding/general-info/' + id);
      return result ? result.data : null;
   }

   onBoardingPreferences = async(id:string, formData:any) => {
      const result = await this.put(formData, 'auth/onboarding/preferences/' + id);
      return result ? result.data : null;
   }

   partnerMatched = async(invitation_token: string, activities: any) => {
      const result = await this.post({activities},'auth/partner-matched/' + invitation_token)
      return result ? result.data : null
   } 

   restartPlanning = async (id: string) => {
      const result = await this.get(`auth/${id}/restart-planning`) 
      return result ? result.data : null
  }

  declineInvitation = async (formData: any, id: string) => {
      const result = await this.patch(formData,`auth/${id}/decline-invitation`)
      return result ? result.data : null
   }

   verifyResetToken =async (token:string) => {
      const result = await this.get(`auth/verify-reset-token/${token}`)
      return result ? result.data : null
   }
}

export default AuthService