import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Row, Col, Divider } from 'antd';
import { FacebookWhiteIcon, TwitterWhiteIcon, InstaWhiteIcon,
    LocationIcon, PhoneWhiteIcon, EmailWhiteIcon, TikTokIcon } from '../../../../components/icons/Icons';
import logo from '../../../../assets/svg/logo-text-row.svg';

import './FooterView.less';
import moment from 'moment';

const FooterView = () => {
    const history = useHistory();   
    return (
        <>
        <Row justify='center' className={`footer-container ${localStorage.getItem('user') && window.innerWidth <= 768 ? `mobile-auth` : ''}`}>
            <Col span={20}>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
                        <Row>
                            <img src={logo} alt="Secret Dates" className="footer-logo" />
                        </Row>
                    </Col>
                    {/* xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} */}
                    {/* <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <Row>
                            <Col sm={8} md={6} lg={12}>
                                <h3 className="about-us">About Us</h3>
                            </Col>
                            <Col sm={2} lg={0}>
                                <Divider className='footer-divider' type='vertical'/>
                            </Col>
                            <Col sm={8} md={6} lg={13}>
                                <h3 className="support">Support</h3>
                            </Col>
                        </Row>
                    </Col> */}
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Row justify={ window.innerWidth <= 768 ? 'center' : 'end' }>
                            <Col className='footer-small-icon' xs={3} sm={3} md={8} lg={24}>
                                <Row align='middle'>
                                    <Col sm={8} md={4} lg={3} >
                                        <a href="https://www.facebook.com/SecretDateNights" target="_blank">
                                            <FacebookWhiteIcon/>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8}>
                                        <a className="social-media-txt" href="https://www.facebook.com/SecretDateNights" target="_blank">Facebook</a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='footer-small-icon' xs={3} sm={3} md={8} lg={24}>
                                <Row align='middle'>
                                    <Col sm={8} md={4} lg={3} >
                                        <a href="https://twitter.com/SecrtDateNights" target="_blank">
                                            <TwitterWhiteIcon/>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8}>
                                        <a className="social-media-txt" href="https://twitter.com/SecrtDateNights" target="_blank">Twitter</a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='footer-small-icon' xs={3} sm={3} md={8} lg={24}>
                                <Row align='middle'>
                                    <Col sm={8} md={4} lg={3} >
                                        <a href="https://www.instagram.com/secretdatenights/" target="_blank">
                                            <InstaWhiteIcon/>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8}>
                                        <a className="social-media-txt" href="https://www.instagram.com/secretdatenights/" target="_blank">Instagram</a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='footer-small-icon' xs={3} sm={3} md={8} lg={24}>
                                <Row align='middle'>
                                    <Col sm={8} md={4} lg={3} >
                                        <a href="https://www.tiktok.com/@secretdatenights" target="_blank">
                                            <TikTokIcon className="tiktok-icon"/>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8}>
                                        <a className="social-media-txt" href="https://www.tiktok.com/@secretdatenights" target="_blank">Tiktok</a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='footer-small-icon' xs={3} sm={3} md={8} lg={0} xl={0} xxl={0}>
                                <Row align='middle' style={{ flexDirection: 'row' }}>
                                    <Col sm={8} md={4} lg={3}>
                                        <EmailWhiteIcon />
                                    </Col>
                                    <Col xs={0} sm={0} md={8}>
                                        <a className="social-media-txt" href="mailto:team@secretdatenights.com" target="_blank">team@secretdatenights.com</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={8}>
                        {/* <Row className="location-div">
                            <Col style={{ paddingRight: 10 }}>
                                <LocationIcon />
                            </Col>
                            <Col style={{ width: '60%'}}>
                                <h4 style={{ color: 'white' }}>
                                    Palm Jumeirah, Dubai 
                                </h4>
                            </Col>
                        </Row>
                        <Row className="phone-div">
                            <Col style={{ paddingRight: 10 }}>
                                <PhoneWhiteIcon />
                            </Col>
                            <Col>
                                <h4 style={{ color: 'white' }}>
                                    056 852 4418
                                </h4>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col style={{ paddingRight: 10 }}>
                                <EmailWhiteIcon />
                            </Col>
                            <Col>
                                <a className="social-media-txt" href="mailto:team@secretdatenights.com" target="_blank">team@secretdatenights.com</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={20}>
                <Row>
                    <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                        <Divider className='footer-terms-divider'/>
                    </Col>
                </Row>
                <Row justify={ window.innerWidth <= 768 ? 'center' : 'start' } className={`copy-write-div ${ window.innerWidth <= 768 ? 'margin-top-s' : 'margin-top-m' }`}>
                    <Col>
                        <h6 style={{ color: 'white' }}>
                            Secret Dates © { `${moment().get('year')}` } <span className='text-gray'>|</span> 
                                <a onClick={() => history.push('/terms-and-conditions')} style={{ textDecoration: 'none', color: 'white'}}> Terms & Conditions </a> <span className='text-gray'>|</span> 
                                <a onClick={() => history.push('/privacy-policy')} style={{ textDecoration: 'none', color: 'white'}}> Privacy Policy</a>
                        </h6>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    );
}

export default withRouter(FooterView)