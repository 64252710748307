import React from 'react';
import { Button, Col, Modal, Row, Typography } from "antd";
import { ExclamationCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';

export default class DialogUtils {

    /**
     * 
     * @param content 
     * @param onOk 
     * @param onCancel 
     */
    static confirm(content: any, onOk?: Function, onCancel?: Function) {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: content,
            onOk: () => { if (onOk) onOk() },
            onCancel: () => { if (onCancel) onCancel() }
        });
    }


    /**
     * 
     * @param content 
     * @param onOk 
     */
    static error(content: any, onOk?: Function) {
        const { Title, Text } = Typography
        Modal.error({
            centered: true,
            icon: null,
            width: 250,
            style: {
                maxWidth: '100vw',
            },
            content: (
                <Row justify='center'>
                    <Col span={24}>
                        <div style={{ textAlign: "center" }}>
                            <ExclamationCircleFilled style={{ fontSize: "60px", color: '#EA3B3B' }} />
                            <Title style={{ color: "#1b144c" }} level={3}>Error</Title>
                            <Text style={{ color: "#b2a1a1" }}>{content}</Text>
                        </div>
                    </Col>
                </Row>
            ),
            onOk: () => { if (onOk) onOk() }
        });
    }


    /**
     * 
     * @param content 
     * @param onOk 
     */
    static success(content: any, onOk?: Function) {
        Modal.success({
            centered: true,
            content: content,
            onOk: () => { if (onOk) onOk() }
        });
    }
}