import SD_PLACEHOLDER_IMAGE from '../assets/png/sd-placeholder.png';
/**
 * App configuration
 */
 class APPConfig
 {
     /**
      * api url endpoint
      * sample: http://localhost:3000/api
      */
     static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https://sd-api.ark.ph/api';
     // static API_URL:string ='http://localhost:4000/api';
     // static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/api';
     static DEFAULT_TIMEZONE:string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'Asia/Dubai';
     static SOCKET_URL:string = process.env.REACT_APP_API_SOCKET_URL ? process.env.REACT_APP_API_SOCKET_URL : 'https://sd-notif.ark.ph/';
     static FB_APP_ID:string = process.env.REACT_APP_FB_APP_ID ? process.env.REACT_APP_FB_APP_ID : "";
     static GOOGLE_CLIENT_ID:string = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : "";
     static GOOGLE_SECRET_KEY:string = process.env.REACT_APP_GOOGLE_SECRET_KEY ? process.env.REACT_APP_GOOGLE_SECRET_KEY : "";
     static SWIPEABLE_MAX_LIMIT:number = process.env.REACT_APP_SWIPEABLE_MAX_LIMIT ? parseInt(process.env.REACT_APP_SWIPEABLE_MAX_LIMIT) : 20
     static MINIMUM_AGE:number = process.env.REACT_APP_MINIMUM_AGE ? parseInt(process.env.REACT_APP_MINIMUM_AGE) : 18
     static ALLOW_DUBAI_NUMBERS_ONLY: boolean = process.env.REACT_APP_ALLOW_DUBAI_NUMBERS_ONLY ? Boolean(parseInt(process.env.REACT_APP_ALLOW_DUBAI_NUMBERS_ONLY)) : false
     static PRICE_RATINGS = ["$","$$","$$$"]
     static SD_PLACEHOLDER_IMAGE = SD_PLACEHOLDER_IMAGE
     static DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY ? process.env.REACT_APP_DEFAULT_COUNTRY : "United Arab Emirates"
 }
 
 export default APPConfig;