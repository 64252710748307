import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from '../services/api/AuthService';


const ProtectedRoute = ({auth,component:Component, onboarding = false, setPageHeader, onSkipOnboarding = () => {}, ...rest}:any) => {
    const authService = new AuthService();
    const user = authService.getUser();
    const isFullyRegistered = ((user?.customer?.name && (!user?.google_user_id || !user?.fb_user_id) && user?.is_active) || (user?.customer?.name && user?.google_user_id && user?.is_active) || (user?.customer?.name && user?.fb_user_id && user?.is_active)) ? true : false
    
    
    return (
        <Route
            {...rest}
            render={props => 
                {
                    if(!onboarding && !isFullyRegistered){
                        localStorage.clear()
                    }

                    if(onboarding && isFullyRegistered){
                        return <Redirect to={
                            {
                                pathname:'/',
                                state:{},
                            }
                        }/>
                    }


                    if(!isFullyRegistered && onboarding){
                        return <Component {...props} setPageHeader={setPageHeader} onSkipOnboarding={onSkipOnboarding}/>
                    }

                    if(localStorage.getItem('access_token') && isFullyRegistered) {
                        return <Component {...props} setPageHeader={setPageHeader} />
                    }
                    else{
                        return <Redirect to={
                            {
                                pathname:'/',
                                state:{},
                            }
                        }/>
                    }
                }
            }
        />

    );
}

export default ProtectedRoute;