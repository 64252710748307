import React, { useEffect, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import reset_email from '../assets/svg/success-password-email.svg';
import reset_success from '../assets/svg/success-password-reset.svg';
import warning from '../assets/svg/warning.svg';

// const successStyle = {color: '#52C41A', fontSize: 70, marginBottom: 20};
const failStyle = { color: '#FF4D4F', fontSize: 70, marginBottom: 20 };
const imgStyle = { width: 60, marginBottom: 0 };


const DynamicInfoModal = ({ title, content, info, icon, visible, btnText, cancelBtnText, afterClose, setVisible, width = 260, confirmMode = false, confirmAction = null }: any) => {

    const [open, setOpen] = useState(false);
    const [headIcon, setHeadIcon]: any = useState(null);
    useEffect(() => {
        const initialize = () => {
            setOpen(visible);
            switch (icon) {
                case 'success': setHeadIcon(<img src={reset_success} alt="reset-success" style={imgStyle} />);
                    break;
                case 'failed': setHeadIcon(<CloseCircleFilled style={failStyle} />);
                    break;
                case 'warning': setHeadIcon(<img src={warning} alt="reset-warning" style={imgStyle} />);
                    break;
                case 'reset-email': setHeadIcon(<img src={reset_email} alt="reset-email" style={imgStyle} />);
                    break;
                case 'reset-success': setHeadIcon(<img src={reset_success} alt="reset-success" style={imgStyle} />);
                    break;
                default: setHeadIcon(null);
                    break;
            }
        }
        initialize();
        return () => {
            setOpen(false);
        }
    }, [visible, icon]);




    return (
        <Modal
            visible={open}
            centered
            onCancel={() => setVisible(false)}
            footer={null}
            destroyOnClose={true}
            afterClose={() => afterClose ? afterClose() : null}
            width={width}
            style={{ maxWidth: '70vw' }}
        >
            <div className="dynamic-info text-center text-secondary" style={{ overflowWrap: 'break-word' }}>
                {headIcon ? headIcon : null}
                {title ? (<h2 className="text-secondary">{title}</h2>) : ''}
                {info ? (<h2 className="text-secondary">{info}</h2>) : null}
                {React.isValidElement(content) ? content : <p className="fw-500 text-gray">{content}</p>}
                {
                    !confirmMode ? (<Button style={{ marginTop: '15px' }} type="primary" htmlType="button" onClick={() => setVisible(false)}>
                        {btnText ? btnText : 'Ok'} </Button >) : (
                        <Space >
                            <Button htmlType="button" onClick={() => setVisible(false)}>
                                {cancelBtnText ? cancelBtnText : "CANCEL"}
                            </Button >
                            <Button type="primary" htmlType="button" onClick={() => {
                                confirmAction();
                                setVisible(false)
                            }}>
                                {btnText ? btnText : 'Ok'}
                            </Button >
                        </Space>)
                }

            </div>
        </Modal>
    );
}

export default DynamicInfoModal