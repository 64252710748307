/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {useHistory, useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client'
import moment  from 'moment'

import { ClockCircleOutlined} from '@ant-design/icons'
import { Col, Row, Empty, Table } from 'antd'

import './NotificationsStyle.less'
import ManageNotificationService from '../../services/api/ManageNotificationService'

import APPConfig from '../../config'


const socket = socketIOClient(APPConfig.SOCKET_URL, { transports: ['websocket', 'polling', 'flashsocket'] })

const noDataPlaceholder = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Notifications Found'} />

const emptyStyle: any = { 
  position: 'absolute', 
  top: 50, left: 0, right: 0, bottom: 50, 
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center' 
}

const NotificationsView = () => {
  const history = useHistory();
  const [notificationsData, setNotificationsData]: any = useState([])
  const manageNotificationService = new ManageNotificationService()
  const [user, setUser]:any = useState();
  
  useEffect(() => {
      if (!localStorage.getItem('user')) {
        onLogout();
      } else {
          const data:any = localStorage.getItem('user');
          const user = JSON.parse(data)
          setUser(user)
          loadResources()
          socket.on('customer_notifications_'+user?.customer?._id, () => {
              loadResources()
          })
          
          return () => {
              socket.disconnect()
          }
      }
  }, [])

  const onLogout = async () => {
      // let api = new AuthService();
      // const result =  await api.logout(user._id);
      // if(result){
          localStorage.clear();
          history.push('/');
      // }
  }

  const loadResources = async (filter: any = '', page: any = 1, limit: any = 5) => {
    const result = await manageNotificationService.search(filter, page, limit)
    setNotificationsData(result.notifications)
    return result.notifications
  }

  const handleMarkAsRead = async (id: string) => {
    const notif = notificationsData.find((item: any) => item._id === id)
    if (!notif.read) {
      await manageNotificationService.markAsRead(id)
    }
    loadResources()
  }

  return (notificationsData || []).length ? (<>
    {
      (notificationsData || []).map((data: any) => {
        const {_id, title, description, createdAt, read, module_type, company_id, module_entity_id, type, recipient_user_id } = data
        let renderNotification = <><span className='title'>{title}</span><span className="desc">{description}</span></>
        if(type === "customer_invitation_expired"){
          const  [first, second] = String(description).split("{partner}")
          renderNotification = <><span className='desc'>{first}</span><span className='title'>{title}</span><span className='desc'>{second.trim()}</span></>
        }

        if(type === "customer_date_reminder"){
          const [activity_type, cities, booking_date] = String(description).split("/")
          renderNotification = <>
            <span className='desc'>{`Date Reminder: `}</span><br/>
            <span className='title'>{activity_type}</span>
            <span className='desc'>at&nbsp;</span>
            <span className='title'>{cities}</span>
            <span className='desc'>on&nbsp;</span>
            <span className='title' style={{ paddingRight: 0}}>{booking_date}</span>
            <span className='desc'>.</span>
            </>
        }
        

        return (
          <Row key={_id} className={`notifications__header-list ${!read ? 'unread' : ''}`} onClick={async () => {
            await handleMarkAsRead(_id)
            if(type === "customer_invitation_sent" && recipient_user_id === user?.customer?._id){
              return history.push(`/date-nights/accept-invitation/swiping-game`,{ accepting: true, booking_id: module_entity_id })
            }

            if(type === "customer_date_reminder"){
              return history.push(`/date-nights/${module_entity_id}/details`,{ date_status: "Completed" })
            }

            history.push(`/date-nights/${module_entity_id}/details`)
          }}>
              <Col className='c-pointer' span={24}>
                {renderNotification}
              </Col>
              <Col span={24} className='time-updated'>
                <ClockCircleOutlined />{moment(createdAt).fromNow()}
              </Col>
              { !read ? <span className='isRead'/> : null }
          </Row>
        )
      })
    }
  </>) : (
    <div style={emptyStyle}>
       <Row justify='center'>
        <Col>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Notifications Found'} />
        </Col>
      </Row>
    </div>
  )
}

export default React.memo(NotificationsView)