import APIService from "./APIService";

class ManageNotificationService extends APIService {
  getAllNotification = async () => {
    const result = await this.get('notifications/search/')
    return result ? result.data : null
  }
  search = async (search: string = '', page: any = 1, limit: any = 10) => {
    const result = await this.get(`notifications/search?generic=${search}&page=${page}&limit=${limit}`)
    return result ? result.data : null
  }
  markAsRead = async (id: string) => {
    const result = await this.patch({},`notifications/${id}`)
    return result ? result.data : null
  }
  markAllAsRead = async () => {
    const result = await this.put({}, 'notifications/read-all')
    return result ? result.data : null
  }

  deleteAll = async (ids = []) => {
    const result = await this.put({ids}, 'notifications/delete-all')
    return result ? result.data : null
  }
}

export default ManageNotificationService