import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client'
import APPConfig from '../../config';


const socket = socketIOClient(APPConfig.SOCKET_URL, { transports: ['websocket', 'polling', 'flashsocket'] })
const useLogout = () => {

    const [hasLoggedOut, setHasLoggedOut] = useState(false)

    useEffect(() => {
        const data:any = localStorage.getItem('user');
        const user = JSON.parse(data) || {}
        socket.on('customer_auth_logout_'+user?._id, (msg: any) => {
            setHasLoggedOut(msg.loggedOut)
        })
        
        return () => {
            socket.disconnect()
        }

    },[])

    return hasLoggedOut

}

export default useLogout