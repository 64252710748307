import React, { Component } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";
import PublicRoute from "./hoc/PublicRoute";
import ProtectedRoute from './hoc/ProtectedRoute';
import AuthService from "./services/api/AuthService";
import LayoutView from "./views/layout/LayoutView";
import AppContainer from "./AppContainer";

interface IAppState {
  isLoading:Boolean
  errorVisible:Boolean
}
const authService = new AuthService();
const LandingPage:any = lazyComponentLoader(() => import('./views/landing-page/LandingPageView'));
const Login:any = lazyComponentLoader(() => import('./views/auth/login/LoginView'));
const Forgot:any = lazyComponentLoader(() => import('./views/auth/forgot/ForgotView'));
const Reset:any = lazyComponentLoader(() => import('./views/auth/reset/ResetView'));
const Register:any = lazyComponentLoader(() => import('./views/auth/register/RegisterView'));
const AccountSettings:any = lazyComponentLoader(() => import('./views/settings/SettingsView'));
const Onboarding:any = lazyComponentLoader(() => import('./views/auth/register/Onboarding/OnboardingView'));
const DateNightsView:any = lazyComponentLoader(() => import('./views/date-nights/DateNightsView'));
const UpcomingDatesDetails:any = lazyComponentLoader(() => import('./views/date-nights/UpcomingDates/Details'));
const Profile:any = lazyComponentLoader(() => import('./views/profile/ProfileView'));
const DateInspiration:any = lazyComponentLoader(() => import('./views/date-inspiration/DateInspiration'));
const NotificationsAllView:any = lazyComponentLoader(() => import('./views/notifications/NotificationsAllView'));
const PrivacyPolicy:any = lazyComponentLoader(() => import('./views/privacy-policy/PrivacyPolicy'));
const TermsAndConditions:any = lazyComponentLoader(() => import('./views/termsandconditions/TermsAndConditions'));


//Swiping Game
const SwipingGame: any = lazyComponentLoader(() => import('./views/swiping-game/SwipingGameView'));
const PartnerInvitation: any = lazyComponentLoader(() => import('./views/swiping-game/PartnerInvitationView'));


//Plan Date
const PlanDate: any = lazyComponentLoader(() => import('./views/plan-date/PlanDateView'));

//Plan Summary
const PlanSummary: any = lazyComponentLoader(() => import('./views/plan-summary/PlanSummaryView'));

//Redirects
const InvitationRequest: any = lazyComponentLoader(() => import('./views/redirects/InvitationRequestView'));
const InvitationAccepted: any = lazyComponentLoader(() => import('./views/redirects/InvitationAcceptedView'));

let errorTimeout: any;
class App extends Component<any, IAppState> {
  
  constructor(props:any) {
    super(props);
    //initialize state here
    this.state = {
        isLoading: false,
        errorVisible: true
    }

    const self = this;
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      self.setState({isLoading: true});
      
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      self.setState({isLoading: false});
      
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({isLoading: false});
      return Promise.reject(error);
    });
  }

  showError(errors:any){
    clearTimeout(errorTimeout)
    
    errorTimeout = setTimeout(() => {
      let content: any = null;
      
      if (isArray(errors)) {
        
        const [error] = errors
        if((error?.context?.key === 'refresh_token') || (error?.context?.key === 'access_token')){
          content = (<div>You have been logged out. Please login again.</div>)
          DialogUtils.error(content, () => {
            localStorage.clear();
            window.location.reload();
          });
          return
        }else{
          content = (<div>{error?.message}</div>)
          DialogUtils.error(content);
          return
        }

        // );
      } else if(errors?.message === "Invalid Booking Link"){
        DialogUtils.error(errors?.message, () => {
          window.location.replace("/date-nights")
        });
        return
      }
       else {
        content = errors === undefined || errors.message.includes('Network Error') ? 'No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again.' : errors.message;
      }
      // const err = content? content.split('Error: ') : '';
      DialogUtils.error(content);
    },500)
    
  }
  
  render() {

    return (
      <div>
        <Router>
          <AppContainer>
            <Switch>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/login'
              component={Login}/>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/reset-password/:token'
              component={Reset}/>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/forgot-password'
              component={Forgot}/>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/register'
              component={Register}/>
              <PublicRoute
              restricted={false}
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/invitation-request/:invitation_token'
              component={InvitationRequest}/>
              <PublicRoute
              restricted={false}
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/invitation-accepted/:booking_id'
              component={InvitationAccepted}/>
              
              
              <LayoutView>
                  <ProtectedRoute onboarding={true} exact path={'/register/onboarding/:id'} component={Onboarding} />
                  <PublicRoute
                    restricted={false}
                    auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                    exact path={["/"]}
                    component={LandingPage}/>
                  <PublicRoute 
                    restricted={false}
                    auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                    exact path='/get-inspired/:group_name/swiping-game'
                    component={SwipingGame}/>
                  <PublicRoute 
                    restricted={false}
                    auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                    exact path='/date-invitation-request/:invitation_token/swiping-game'
                    component={SwipingGame}/>
                  <PublicRoute
                    restricted={false}
                    auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                    exact path='/get-inspired/:group_name/swiping-game/send-invite'
                    component={PartnerInvitation}/>
                  <PublicRoute 
                    auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                    restricted={false}
                    exact path='/privacy-policy'
                    component={PrivacyPolicy}/>
                  <PublicRoute 
                    auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                    restricted={false}
                    exact path='/terms-and-conditions'
                    component={TermsAndConditions}/>

                  {/* <ProtectedRoute exact path={'/settings'} component={AccountSettings} /> */}
                  <ProtectedRoute exact path={'/notifications'} component={NotificationsAllView} />
                  <ProtectedRoute exact path={'/settings'} component={AccountSettings} />
                  <ProtectedRoute exact path={'/profile'} component={Profile} />
                  {/* Date Nights */}
				          <ProtectedRoute exact path={'/date-nights'} component={DateNightsView} />
                  <ProtectedRoute exact path={'/date-nights/accept-invitation/swiping-game'} component={SwipingGame} />
                  <ProtectedRoute exact path={'/date-nights/:booking_id/details'} component={UpcomingDatesDetails} />
                  {/* Plan Date */}
                  <ProtectedRoute exact path={'/plan-date'} component={PlanDate} />
                  <ProtectedRoute exact path={'/plan-date/send-invite'} component={PartnerInvitation} />
                  <ProtectedRoute exact path={'/plan-date/send-invite/plan-summary'} component={PlanSummary} />
                  <ProtectedRoute exact path={'/get-inspired/:group_name/swiping-game/send-invite/plan-summary'} component={PlanSummary} />
                  <ProtectedRoute exact path={'/plan-date/swiping-game'} component={SwipingGame} />
                  {/* Date Inspirations */}
                  <ProtectedRoute exact path={'/date-inspirations'} component={DateInspiration} />
              </LayoutView>

              {/* Redirect all 404's to home */}
              <Redirect to={"/"}/>
                
            </Switch>
          </AppContainer>
        </Router>
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;