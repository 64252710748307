import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client'
import APPConfig from '../../config';


const useHasUpdated = () => {

    const [hasUpdated, setHasUpdated]: any = useState(0)

    const triggerUpdate = () => {
        setHasUpdated(Math.random() * 10)
    }

    

    return [hasUpdated, triggerUpdate]

}

export default useHasUpdated