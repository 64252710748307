//Put your App Level Hooks here

import React, { useEffect } from "react"
import { ComponentProps } from "react"
import { Switch, useHistory, useLocation, withRouter } from "react-router-dom"
import WindowUtils from "./utils/WindowUtils"
import useLogout from "./services/custom-hooks/useLogout"
import { GoogleOAuthProvider } from '@react-oauth/google';
import APPConfig from "./config"

const DATE_NIGHTS_TAB_KEY = "date_tabs_key"
const DATE_NIGHTS_FILTER_KEY = "date_tabs_filter_key"
const DATE_NIGHTS_FILTER_VISIBLITY_KEY = "date_tabs_filter_visibility_key"

//NOTE: Create your own useEffect per logic
const AppContainer = (props: ComponentProps<any>) => {
    const location = useLocation()
    const history = useHistory();
    const hasLoggedOut = useLogout();

    useEffect(() => {

        if(location.pathname.includes('/swiping-game')){
            window.scrollTo(0, document.body.scrollHeight)
        }else{
            window.scrollTo(0,0)
        }

        if(!location.pathname.includes("/date-nights")){
            localStorage.removeItem(DATE_NIGHTS_TAB_KEY)
            localStorage.removeItem(DATE_NIGHTS_FILTER_KEY)
            localStorage.removeItem(DATE_NIGHTS_FILTER_VISIBLITY_KEY)
        }

    },[location])

    useEffect(() => {
        if(hasLoggedOut){
            localStorage.clear();
            history.push("/")
        }
    },[hasLoggedOut])


 
    
    return <>
    <GoogleOAuthProvider clientId={APPConfig.GOOGLE_CLIENT_ID}>
        {props.children}
    </GoogleOAuthProvider>
    </>
 }

 export default AppContainer